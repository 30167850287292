<template>
  <div>
    <v-chip
      v-if="showLabel"
      class="absolute-left-chip text-xs"
      color="#1d1d1dd4"
      small
    >
      <v-icon
        v-if="isPremium"
        class="mr-1"
        color="#FFCC00"
        size="18"
      >
        {{ icons.mdiCrownOutline }}
      </v-icon>
      {{ isPremium ? t('video_quality.premium') : t('video_quality.basic') }}
    </v-chip>

    <v-chip
      v-if="showTime"
      class="absolute-right-chip text-xs"
      color="#1d1d1dd4"
      small
    >
      {{ time }}
    </v-chip>

    <span
      v-if="showCenterIcon"
      name="centerIcon"
      style="
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        background-color: #00000099;
        border-radius: 100px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <v-icon
        color="white"
        size="18"
      >
        {{ icons.mdiCart }}
      </v-icon>
    </span>
  </div>
</template>

<script>
import { mdiCrownOutline, mdiCart } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'

export default {
  props: {
    time: {
      type: String,
      default: '',
    },
    isPremium: {
      type: Boolean,
      default: true,
    },
    showTime: {
      type: Boolean,
      default: true,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    showCenterIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useUtils()

    return {
      t,

      icons: {
        mdiCrownOutline,
        mdiCart,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  .absolute-right-chip {
    bottom: 10px;
    right: 10px;
    position: absolute;
  }

  .absolute-left-chip {
    bottom: 10px;
    left: 10px;
    position: absolute;
  }
</style>
