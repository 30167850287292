import { ref } from '@vue/composition-api'
import { getUserPaymentMethods } from '@api'

export default function useLanding() {
  const paymentMethods = ref([])

  const fetchPaymentMethods = async () => {
    try {
      const response = await getUserPaymentMethods()
      paymentMethods.value = response.data
    } catch (e) {
      console.error(e)
      paymentMethods.value = []
    }
  }

  return {
    // data
    paymentMethods,

    // mrthods
    fetchPaymentMethods,
  }
}
